// 看更多
"use client";
import Link from "next/link";
import { revalidatePage } from "@/app/actions";

const LoadMore = ({
  path = null,
  onLoad = null,
  loadingMore = false,
  cl = "",
}) => {
  if (path)
    return (
      <Link
        onClick={() => revalidatePage()}
        href={path}
        className={`${cl} relative z-[2] text-btn1 border-t-1 border-[#1e1e1e] dark:border-[#f7f7f8] border-opacity-[.3] text-center max-w-[400px] block mx-auto
        mt-[50px] mb-[60px] umd:my-[45px]`}
      >
        <span
          className="inline-block py-[10px] px-[18px] rounded-b-[2px] trans text-black  hover:bg-black hover:text-white 
      "
        >
          + LOAD MORE
        </span>
      </Link>
    );

  return loadingMore ? (
    <div className="text-center mx-auto h-[38px] my-[90px] ulg:mb-[60px] usm:!mb-[30px]">
      <img
        className="w-[130px] inline-block"
        src="/images/layout/loading.gif"
        alt="loading"
      />
    </div>
  ) : (
    <div
      onClick={onLoad}
      className="cp relative z-[2] text-btn1 border-t-1 border-[#1e1e1e] dark:border-[#f7f7f8] border-opacity-[.3] text-center max-w-[400px] block mx-auto
      my-[90px] ulg:mb-[60px] usm:!mb-[30px]"
    >
      <span
        className="inline-block py-[10px] px-[18px] rounded-b-[2px] trans text-black  hover:bg-black hover:text-white 
      "
      >
        + LOAD MORE
      </span>
    </div>
  );
};

export default LoadMore;
