// 精選新聞
import moment from "moment";
import { useRouter } from "next/navigation";
import Link from "next/link";
import Image from "next/image";

const SelectedNewsComponent = ({ t, news }) => {
  const router = useRouter();

  // 點擊分類跳轉
  const handleCategoryClick = (e) => {
    e.preventDefault();
    router.push(`/news?category=${news?.category}`);
  };

  return (
    <Link
      href={`/news/${news?.slug}`}
      className="latest-news flex gap-x-[15px] group pb-[26px] border25 border-b-1 ulb:pb-[20px] ulg:border-0 ulg:!pb-0
      umd:!gap-x-[10px] w-full"
    >
      <div className="flex-grow flex flex-col justify-between gap-y-[22px]">
        {/* 標題 */}
        <h2 className="hover-text text-btn3b text-black9 line-clamp-3 lighten-text">
          {news?.title}
        </h2>

        {/* 新聞類別、時間 */}
        <p className="text-btn2">
          <span
            className="trans text-pale group-hover:text-pale9"
            onClick={handleCategoryClick}
          >
            {t(news?.category?.toUpperCase())}
          </span>
          <span className="text-btn1 text-black2"> ／ </span>
          <span className="text-black4">
            {moment(news?.displayTime).format("YYYY.MM.DD")}
          </span>
        </p>
      </div>

      {/* 新聞圖片 */}
      <div className="img-box w-[72px] h-[106px] overflow-hidden rounded-[2px] ulg:w-[124px] ulg:h-[96px] uxs:hidden">
        <Image
          className="hover-img"
          src={
            news?.image || news?.imageFb || "/images/news/default_related.svg"
          }
          alt={news?.title}
          width={72}
          height={106}
          sizes="130px"
        />
      </div>
    </Link>
  );
};

export default SelectedNewsComponent;
