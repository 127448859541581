"use client";
// 首頁 大家都在看
import { useState, useEffect } from "react";
import newsClient from "@/common/apiClient/newsClient"; // scroll 拖拉 用
import PopularNewsComponent from "../basic/PopularNewsComponent"; // 大家都在看 新聞元件
import useHorizontalDrag from "@/hook/useHorizontalDarg";

const IndexPopular = ({ locale }) => {
  const { wrapRef, mouseDownHandler, isDragging } = useHorizontalDrag(); // 處理拖拉用

  const [popularNews, setPopularNews] = useState([]); // 熱門新聞
  const [loading, setLoading] = useState(true);

  // 取得熱門新聞五篇
  const getPopularNews = async () => {
    try {
      const result = await newsClient.getPopularNewsByCategoryCode(locale);

      if (result) {
        setPopularNews(result);
        setTimeout(() => {
          setLoading(false);
        }, 200);
      }
    } catch (e) {
      console.error(e);
    }
  };
  useEffect(() => {
    getPopularNews();
  }, []);

  return (
    <div className="index-scroll-wrapper pt-[30px] pb-[40px] umd:pt-[20px] umd:pb-[30px]">
      {/* 背景 */}
      <div className="bg" />

      <div className="content w-[1072px] mx-auto relative uxl:w-full">
        <p>大家都在看</p>

        <div
          className="scroll-wrapper w-[1195px] left-[-61px] uxl:w-[calc(100vw-244px)] usm:!pl-[15px] uxl:left-0 ulg:!w-full"
          ref={wrapRef}
          onMouseDown={(e) => mouseDownHandler(e)}
        >
          <div className="others-watching-list space-x-[15px] pl-[61px] uxl:pl-[25px] blg:pl-[30px] bmd:pl-[30px] usm:!pl-0">
            {loading
              ? new Array(8)
                  .fill(0)
                  .map((_, i) => (
                    <div
                      key={"popular-news-" + i}
                      className="rounded-[4px] skelton w-[250px] h-[300px]"
                    />
                  ))
              : popularNews?.map((news, i) => (
                  <PopularNewsComponent
                    key={news.id + "index-popular-news" + i}
                    i={i + 1}
                    news={news}
                    isDragging={isDragging}
                  />
                ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default IndexPopular;
