// 專欄列表 元件
import Link from "next/link";
import Image from "next/image";
import { useRouter } from "next/navigation";

const ColumnListComponent = ({ column, location = null }) => {
  const router = useRouter();
  return (
    <div
      className={`bg-white  px-[25px] pt-[20px] pb-[30px] rounded-[2px] flex-shrink-0
    ${location === "column" ? "border-1 border-black1" : "min-h-[429px]"}`}
    >
      <Link
        href={`/author/${column?.author}`}
        className={`${
          location !== "column" ? "index-author-item" : ""
        } flex justify-start items-center space-x-[15px] pb-[18px] group`}
        onClick={() => router.push(`/author/${column?.author}`)}
      >
        {/* 作者圖片 */}
        <div
          className="img-box trans w-[86px] h-[86px] flex-shrink-0 rounded-[50%] overflow-hidden border-1 border-transparent
        after:content-none usm:w-[75px] usm:h-[75px] group-hover:border-black2"
        >
          <Image
            className="!object-contain"
            width={86}
            height={86}
            src={column?.image || "/images/news/default_related.svg"}
            alt={"專欄作者" + column?.name}
            sizes="80px"
            loading="lazy"
          />
        </div>

        <div className="space-y-[2px] flex-shrink max-w-full flex-grow-0">
          {/* 作者名稱 */}
          {location === "column" ? (
            <h3 className="decoration-[1px] hover-text text-h5 text-black break-word">
              {column?.name}
            </h3>
          ) : (
            <p className="decoration-[1px] hover-text text-h5 text-black break-word">
              {column?.name}
            </p>
          )}

          {/* 作者類型 */}
          <p className="text-bd1n text-black ">{column?.title}</p>
        </div>
      </Link>

      {/* 文章列表 */}
      {column?.news?.map((v, i) => (
        <Link
          key={"column" + "news" + v?.id}
          href={`/news/${v?.slug}`}
          className={`${
            location !== "column" ? "index-author-item" : ""
          } group py-[22px] border-t-1 border-dotted border-black4 border-opacity-[.5] flex gap-x-[16px] items-center`}
          prefetch={i < 1}
        >
          {/* 文章圖片 */}
          {i === 0 && location !== "column" && (
            <div
              className={`img-box w-[52px] h-[52px] rounded-[2px] overflow-hidden flex-shrink-0 ${
                location === "column" ? "umd:hidden" : ""
              }`}
            >
              <Image
                loading="lazy"
                className="hover-img"
                src={v?.image || "/images/news/default_related.svg"}
                alt={"專欄：" + v?.title}
                width={52}
                height={52}
                sizes="80px"
              />
            </div>
          )}

          {/* 文章標題 */}
          {location === "column" ? (
            <h2 className="hover-text text-btn3b text-black9 line-clamp-2 lighten-text">
              {v?.title}
            </h2>
          ) : (
            <h5 className="hover-text text-btn3b text-black9 line-clamp-2 lighten-text">
              {v?.title}
            </h5>
          )}
        </Link>
      ))}

      {location === "column" && (
        <Link
          href={`/author/${column?.author}`}
          className="py-[10px] trans inline-block text-btn1 text-black  bg-white 
          border-b-1 border-transparent hover:border-black"
        >
          + LOAD MORE
        </Link>
      )}
    </div>
  );
};

export default ColumnListComponent;
